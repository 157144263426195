<h1>
  Controleer afbeeldingen
</h1>
<div class="pagination">
  <font *ngFor="let section of sections let i = index">
    <span class="page-select" [ngClass]="section['id'] == sectionIndex ? 'active' : ''"
      (click)="setCurrentSectionIndex(section['id'])" class="p-3">{{section['name']}}</span>
  </font>
</div>

<div class="chapters-filter" *ngIf="chapters.length > 0">
  <h3>Hoofdstukken:</h3>
  <div class="form-group mb-2" *ngFor="let chapter of chapters">
    <input type="checkbox" (click)="addFilterValue(chapter.id)" name="{{ chapter.id }}" id="{{ chapter.id }}"
      class="mr-2">
    <label for="{{ chapter.id }}">{{ chapter.name }}</label>
  </div>
</div>

<div class="table-search mb-1" *ngIf="sectionIndex">
  <input (keyup)="search($event)">
</div>

<table class="table" *ngIf="sectionIndex">
  <thead class="thead-dark">
    <tr>
      <th scope="col" width="150">Les</th>
      <th scope="col">Afbeelding</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let image of images let i = index">
      <td>
        <span *ngFor="let chapter of image['chapters'] let i = index">
          <a (click)="visitLesson(chapter['chapter']['chapterLessonId'], image['lessonContent']['studyLevel'])"
            style="text-decoration:underline">{{image['lesson']['name']}}
            <small>[{{image['lessonContent']['studyLevel']}}]</small> ({{chapter['chapter']['name']}})</a>
        </span>
      </td>
      <td>
        <div [innerHTML]="text(image['image'])" style="max-width:50%;overflow:hidden;"></div>
      </td>
    </tr>
  </tbody>
</table>